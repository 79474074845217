import client from "./api";
import apiClient from "./intoCultureApiClient";
import axios from "axios";

const cjpApiClientEndpoints = {
  login: "/api/login",
  logout: "/api/logout",
  token: "/api/token",
  balance: "/ic-api/auth/user/balance",
};

/**
 * CJP API Client
 *
 * Extending the IntoCulture API Client with CJP specific endpoints
 */
const cjpApiClient = () => {
  const getEndpointString = (type) => {
    if (type === "balance") {
      return "/ic-api/auth/user/balance";
    }

    const hostnameSplit = window.location.hostname.split(".");
    hostnameSplit[0] = "oauth2";
    let hostname = hostnameSplit.join(".");
    if (hostnameSplit.length < 3) {
      hostname = "oauth2.cjp.nl";
    }
    const slug = cjpApiClientEndpoints[type];

    return window.location.protocol + "//" + hostname + slug;
  };

  const login = async ({ email, password }) => {
    const loginParams = {
      email,
      password,
    };

    return await client.post(getEndpointString("login"), loginParams, false);
  };

  const logout = async () => {
    try {
      await axios.get(getEndpointString("logout"));
    } catch {
      return false;
    }
  };

  /**
   * Need a session token to be able to get an access_token
   *
   * @param {string} sessionToken
   * @returns {Promise<AxiosResponse<any>>}
   * @returns {string} AxiosResponse.access_token
   * @returns {string} AxiosResponse.token_type
   * @returns {string} AxiosResponse.expires_at
   */
  const getAccessToken = async ({ sessionToken }) => {
    const params = {
      headers: {
        Authorization: `Bearer ${sessionToken}`,
      },
    };

    return await axios.get(getEndpointString("token"), params);
  };

  const renewAccessToken = () => {
    try {
      return axios.get("/oauth-api/renew-token");
    } catch (err) {
      return false;
    }
  };

  const balance = async () => {
    return axios.get(getEndpointString("balance"));
  };

  return {
    ...apiClient,
    login,
    logout,
    getAccessToken,
    renewAccessToken,
    balance,
  };
};

export default cjpApiClient();
