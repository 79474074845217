import userService from "./userService";
import apiClient from "../cjpApiClient";
import AuthorizationError from "../../errors/AuthorizationError";
import { LOCAL_STORAGE_REDIRECT_URL } from "../../constants/localStorage";
import cookieHelper from "../../helpers/cookieHelper";

const cjpUserService = () => {
  const { clearUserData } = userService;

  const doLogout = async () => {
    try {
      await apiClient.logout();
      await clearUserData();
    } catch (e) {
      await clearUserData();
    }
  };

  const doLoginRedirect = () => {
    const returnTo = localStorage.getItem(LOCAL_STORAGE_REDIRECT_URL);
    localStorage.removeItem(LOCAL_STORAGE_REDIRECT_URL);

    window.location.assign(returnTo || "/");
  };

  const doLogin = async ({ returnTo, username, password }) => {
    if (returnTo) {
      localStorage.setItem(LOCAL_STORAGE_REDIRECT_URL, returnTo);
    }

    const { access_token, email } = await apiClient.login({
      email: username,
      password,
    });

    if (access_token) {
      cookieHelper.setCookie("cjp_user", email);
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        login: "true",
      });

      doLoginRedirect();
    }
  };

  /**
   * Using session token to fetch and persist renewed access token for oauth purposes
   */
  const renewAccessToken = async () => {
    try {
      const {
        data: { access_token },
      } = await apiClient.renewAccessToken();

      if (!access_token) {
        return false;
      }

      return true;
    } catch (e) {
      return false;
    }
  };

  /**
   * In CJP context there are multiple token types
   * session token
   * access token (for oauth)
   *
   * Forwarding to renewAccessToken to make destinction
   */
  const renewSessionToken = renewAccessToken;

  const getUser = async () => {
    if ((await userService.getUser()) !== "") {
      // assume logged in.
      return true;
    }
    const response = await apiClient.getAuthUser();

    // TODO: Until proper errors are thrown by apiClient, this is the way
    if (response.error) {
      throw new AuthorizationError("getUser failed: " + response.error);
    }

    return response;
  };

  /**
   * If able to renew session/access token, user is authorized
   *
   * Weird keeping getUser();
   * basically only for local/heroku env's
   * where cjp.nl cookies cannot be read
   */
  const isAuthorized = async () => {
    try {
      if (!(await getUser())) {
        return false;
      }

      return true;
    } catch (e) {
      return false;
    }
  };

  const isAuthenticated = isAuthorized;

  const getBalance = async () => {
    const response = await apiClient.balance();
    // TODO: Until proper errors are thrown by apiClient, this is the way

    if (response.error) {
      throw new AuthorizationError(response.error);
    }

    return response.data.balance || 0;
  };

  return {
    ...userService,
    doLogout,
    doLoginRedirect,
    doLogin,
    renewSessionToken,
    getUser,
    isAuthorized,
    isAuthenticated,
    getBalance,
  };
};

export default cjpUserService();
