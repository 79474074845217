<template>
  <popup-window v-slot="scope" ref="popupWindow">
    <h2 class="title title-l">
      Verzilver nu jouw CJP-tegoed voor te gekke uitjes!
    </h2>
    <p>
      Een avondje bios of dagje naar het museum? Fix het nu met jouw CJP-tegoed.
    </p>
    <div class="buttons">
      <button class="button--primary" @click="goTo('https://studenten.cjp.nl')">
        Bekijk het aanbod
      </button>
      <button class="button--secondary" @click="scope.close">Sluiten</button>
    </div>
  </popup-window>
</template>

<script>
import user from "../services/userServices";
import { SESSION_STORAGE_BUDGET_POPUP } from "../constants/sessionStorage";

export default {
  name: "PopupBudget",
  data() {
    return {
      isLoggedIn: false,
      budget: null,
    };
  },
  async mounted() {
    if (!sessionStorage.getItem(SESSION_STORAGE_BUDGET_POPUP)) {
      try {
        this.isLoggedIn = await user.isAuthenticated();
        if (this.isLoggedIn) {
          this.budget = await user.getBalance();
        }
        if (this.budget > 0 && this.isLoggedIn) {
          this.$refs.popupWindow.openPopUp();
        }
      } catch {
        // Not logged in or issue getting balance
      }
      sessionStorage.setItem(SESSION_STORAGE_BUDGET_POPUP, true);
    }
  },
  methods: {
    goTo(location) {
      window.location.href = location;
    },
  },
};
</script>
