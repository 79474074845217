import axios from "axios";
import AuthorizationError from "../errors/AuthorizationError";
import MethodNotAllowedError from "../errors/MethodNotAllowedError";
import IntoCultureApiClientError from "../errors/IntoCultureApiClientError";

const api = axios.create({
  timeout: 25000,
  withCredentials: true,
});

const pendingRequests = [];

const interceptor = async (endpoint, request) => {
  const getIndex = (pending) => pending.endpoint === endpoint;

  const alreadyExecuting = pendingRequests.find(getIndex);

  if (!alreadyExecuting) {
    try {
      pendingRequests.push({ endpoint, request: request() });
      const response = await pendingRequests[
        pendingRequests.findIndex(getIndex)
      ].request;

      pendingRequests.splice(pendingRequests.findIndex(getIndex), 1);
      return response;
    } catch (err) {
      pendingRequests.splice(pendingRequests.findIndex(getIndex), 1);
      throw err;
    }
  }

  return await alreadyExecuting.request;
};

const get = async (endpoint, falseOnFailure = true) => {
  return interceptor(endpoint, async () => {
    try {
      const response = await api.get(endpoint);
      return response.data;
    } catch (err) {
      if (falseOnFailure) {
        return false;
      }

      if (err.response.status === 401) {
        const authError = new AuthorizationError(err.response.data.error);
        authError.request = err.response.request;

        throw authError;
      }

      throw err;
    }
  });
};

const post = async (endpoint, data = {}, falseOnFailure = true) => {
  return interceptor(endpoint, async () => {
    try {
      const response = await api.post(endpoint, data);
      return response.data;
    } catch (err) {
      if (falseOnFailure) {
        console.error("error", err.response.data.message);
        return false;
      }

      if (err.response.status === 401) {
        const authError = new AuthorizationError();
        authError.request = err.response.request;

        throw authError;
      }

      if (err.response.status === 405) {
        const methodNotAllowedError = new MethodNotAllowedError(
          err.response.data.error
        );
        methodNotAllowedError.request = err.response.request;

        throw methodNotAllowedError;
      }

      if (err.response.status === 409) {
        const conflictError = new IntoCultureApiClientError(
          err.response.data.error.message
        );
        conflictError.request = err.response.request;
        throw conflictError;
      }

      throw err;
    }
  });
};

export default { get, pendingRequests, post };
