<template>
  <button
    class="nav-button--switch"
    :aria-label="'Menu' | t"
    @click="toggleMenu()"
  >
    <i v-if="!open" class="fas fa-bars"></i>
    <i v-else class="fas fa-times"></i>
  </button>
</template>

<script>
import EventBus from "./../event-bus";
export default {
  name: "NavigationButtonMobile",
  data() {
    return {
      open: false,
    };
  },
  mounted() {
    EventBus.$on("toggleMenu", () => {
      this.open ^= true;
      const root = document.getElementsByTagName("html")[0];
      if (this.open) {
        root.classList.add("menu-open");
      } else {
        root.classList.remove("menu-open");
      }
    });
  },
  methods: {
    toggleMenu() {
      EventBus.$emit("toggleMenu");
    },
  },
};
</script>
