import Vue from "vue";
import translations from "../services/translations";
import initiateVueComponents from "../initiateVueComponents";

//Translate js-strings with Crafts static message translations
Vue.use(translations);

Vue.config.productionTip = false;

initiateVueComponents(require.context("./", true, /[A-Z]\w+\.(vue)$/));
