<template>
  <div
    class="add-to-cart mobile"
    :class="{
      sticky: stick,
    }"
  >
    <slot></slot>
  </div>
</template>

<script>
import user from "../services/userServices/userService";

export default {
  name: "AddToCart",
  data() {
    return {
      scrollPos: 0,
      stick: true,
      stickHeight: 0,
    };
  },
  async created() {
    await user.isAuthorized();
  },
};
</script>
