<template>
  <div class="category-filters tablet">
    <p v-if="productAmount" class="category-filters__header">
      {{ productAmount }}
      {{ productAmount === 1 ? "result" : "results" | t }}
    </p>
    <div class="category-filters-list">
      <select-list
        v-for="(filter, name) in data.filters"
        :key="name"
        placeholder="type..."
        :limit-type="filter.type"
        :options="data[name]"
        :title="filter.name"
        :limit="filter.limit"
        @setOption="setOption"
      ></select-list>
    </div>
  </div>
</template>

<script>
import EventBus from "./../event-bus";

export default {
  name: "CategoryFiltersDesk",
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    productAmount() {
      if (!this.data || !this.data.products) return;
      return this.data.products.total;
    },
  },
  methods: {
    setOption(option) {
      EventBus.$emit("setCategoryOption", option);
    },
  },
};
</script>
