const cookieHelper = () => {
  const getCookieValue = (a) => {
    const b = document.cookie.match("(^|;)\\s*" + a + "\\s*=\\s*([^;]+)");
    return b ? b.pop() : "";
  };

  const addCookieDomain = (cookieString) => {
    const hostnameSplit = window.location.hostname.split(".");
    let hostname;
    if (hostnameSplit.length < 3) {
      hostname = "cjp.nl";
    } else {
      hostnameSplit.shift();
      hostname = hostnameSplit.join(".");
    }
    cookieString += ";domain=." + hostname;

    return cookieString;
  };

  const setCookie = (name, value) => {
    let cookieString = name + "=" + value + "; Path=/;";

    cookieString = addCookieDomain(cookieString);

    // Set cookie
    document.cookie = cookieString;
  };

  const removeCookie = (name) => {
    let cookieString =
      name + "=; Path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT";

    cookieString = addCookieDomain(cookieString);

    document.cookie = cookieString;
  };

  return {
    getCookieValue,
    setCookie,
    removeCookie,
  };
};

export default cookieHelper();
