<template>
  <a ref="tile" :href="item.url" class="tile" :class="item.type">
    <vue-image
      v-if="item.image"
      :srcset="item.image.srcset"
      :srcset-webp="item.image.srcsetWebp"
      :title="item.name"
      :placeholder="item.image.url"
    ></vue-image>
    <label v-if="item.label" class="label">{{ item.label }}</label>
    <div class="tile__content">
      <p v-if="dateCategory" class="category-label">
        {{ dateCategory }}
      </p>
      <h3 v-if="item.name" class="title-m">{{ item.name }}</h3>
    </div>
  </a>
</template>

<script>
import dayjs from "dayjs";

export default {
  name: "ArticleTile",
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    dateCategory() {
      //Returns date and category with a pipe in between if they're both available
      //Otherwise, show just the one, or none if there aren't any available
      if (!this.item.date.date && !this.item.category.title) return;
      const array = [];
      if (this.item.date) {
        array.push(
          dayjs(this.item.date.date)
            .locale("nl")
            .format("DD MMMM")
        );
      }
      if (this.item.category) {
        array.push(this.item.category.title);
      }
      return array.join(" | ");
    },
  },
};
</script>
