<template>
  <div class="tally-counter">
    <button class="tally-counter__btn" @click="subtract">
      <i class="fal fa-minus"></i>
    </button>

    <input
      v-model.number="amount"
      class="tally-counter__input"
      type="number"
      name="amount"
      step="1"
      min="0"
      :max="max"
    />

    <button class="tally-counter__btn" @click="add">
      <i class="fal fa-plus"></i>
    </button>
  </div>
</template>

<script>
export default {
  name: "InputTallyCounter",
  props: {
    value: {
      type: Number,
      required: true,
    },
    max: {
      type: Number,
      default: null,
    },
  },
  computed: {
    amount: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    add() {
      if (this.value >= this.max) {
        return;
      }

      this.$emit("add");
    },
    subtract() {
      if (this.value <= 0) {
        return;
      }

      this.$emit("subtract");
    },
  },
};
</script>
