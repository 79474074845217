<template>
  <div class="category-filters container mobile">
    <h2 v-if="data" class="title-m--alt">
      {{ data.category.name }}
    </h2>
    <select-box
      v-for="(filter, name) in data.filters"
      :key="name"
      placeholder="type..."
      :limit-type="filter.type"
      :options="data[name]"
      :title="filter.name"
      :limit="filter.limit"
      :class="{ 'select-box--multiple': Object.keys(data.filters).length > 1 }"
      @setOption="setOption"
    ></select-box>
  </div>
</template>

<script>
import EventBus from "./../event-bus";

export default {
  name: "CategoryFiltersMobile",
  props: {
    data: {
      type: Object,
      required: true,
    },
    category: {
      type: String,
      required: false,
      default: null,
    },
  },
  computed: {
    productAmount() {
      if (!this.data) return;
      if (!this.data.products) return;
      return this.data.products.data.length;
    },
  },
  methods: {
    setOption(option) {
      EventBus.$emit("setCategoryOption", option);
    },
  },
};
</script>
