<template>
  <div class="sitemap">
    <div class="sitemap__buttons container">
      <button
        v-for="tab in tabs"
        :key="tab"
        :class="['tab-button', { active: currentTab === tab }]"
        @click="currentTab = tab"
      >
        {{ tab }}
      </button>
    </div>
    <slot :name="currentTabComponent"></slot>
  </div>
</template>

<script>
export default {
  name: "SiteMap",
  data() {
    return {
      currentTab: "sitemap",
      tabs: [
        "sitemap",
        "grid",
        "elements",
        "forms",
        "typography",
        "colors",
        "variables",
      ],
    };
  },
  computed: {
    currentTabComponent() {
      return this.currentTab.toLowerCase();
    },
  },
};
</script>
