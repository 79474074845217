const selectFunctions = {
  props: {
    title: {
      type: String,
      required: false,
      default: null,
    },
    placeholder: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    limit: {
      type: Number,
      required: false,
      default: null,
    },
    limitType: {
      type: String,
      required: false,
      default: "showMore",
    },
  },
  data() {
    return {
      open: false,
      selected: null,
      ignoreLimit: false,
      search: null,
      type: null,
    };
  },
  computed: {
    optionsList() {
      //If there's no limit, or "show more" has been clicked
      if (this.ignoreLimit || !this.limit) return this.options;

      //If searching
      if (
        this.limitType === "input" &&
        !(this.search === null || this.search === "")
      ) {
        return this.options.filter((obj) => {
          return obj.name.toLowerCase().includes(this.search.toLowerCase());
        });
      }

      //Else: show with limit
      return this.options.slice(0, this.limit);
    },
  },
  mounted() {
    this.checkSelected();
  },
  methods: {
    show() {
      if (!this.open) {
        this.open = true;
        //Listen for outside click
        document.addEventListener("click", this.addClick);
      } else {
        this.open = false;
      }
    },
    addClick() {
      //Listen for outside click
      const ignoreClickOnMeElement = this.$refs.selectbox;
      const isClickInsideElement = ignoreClickOnMeElement.contains(
        event.target
      );
      //If clicked outside, close options
      if (!isClickInsideElement) {
        this.open = false;
        this.removeClick();
      }
    },
    removeClick() {
      //Stop listening on close
      document.removeEventListener("click", this.addClick);
    },
    setOption(option) {
      this.removeClick();
      this.open = false;
      if (option.selected) return this.resetOption();

      this.selected = option;
      this.$emit("setOption", {
        value: option ? option.value : null,
        type: this.title.toLowerCase(),
      });
    },
    resetOption() {
      this.selected = null;
      this.$emit("setOption", {
        value: null,
        type: this.title.toLowerCase(),
      });
    },
    checkSelected() {
      const result = this.options.filter((obj) => {
        return obj.selected;
      });
      if (result) this.selected = result[0];
    },
    getOptionName(name) {
      if (this.title === "Date") return this.$options.filters.t(name); //Only translate for "Date"
      return name;
    },
  },
};

export default selectFunctions;
