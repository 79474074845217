<template>
  <div v-if="isLoggedIn" class="budget-info" :class="{ loading: !budget }">
    <p class="title">Budget</p>
    <div class="budget">
      €{{
        budget
          ? parseFloat(budget)
              .toFixed(2)
              .replace(".", ",")
          : "-,--"
      }}
    </div>
  </div>
</template>

<script>
import user from "../services/userServices";
import { SESSION_STORAGE_BUDGET_VALUE } from "../constants/sessionStorage";
import EventBus from "./../event-bus";

export default {
  name: "BudgetInfo",
  data() {
    return {
      isLoggedIn: false,
      budget: null,
    };
  },
  async mounted() {
    this.setEventHandlers();
    this.budget = sessionStorage.getItem(SESSION_STORAGE_BUDGET_VALUE);

    try {
      this.isLoggedIn = Boolean(await user.isAuthenticated());

      if (this.isLoggedIn) {
        const budget = await user.getBalance();
        if (isNaN(parseFloat(budget)) === false) {
          this.budget = budget; // Just in case something unexpected comes from the api.
        }
      }
    } catch {
      // Not logged in or issue getting balance
      this.isLoggedIn = false;
    }

    sessionStorage.setItem(SESSION_STORAGE_BUDGET_VALUE, this.budget);
  },
  methods: {
    setEventHandlers() {
      EventBus.$on("loggedIn", (event) => {
        if (event === false) {
          this.isLoggedIn = false;
          this.budget = null;
        }
      });
    },
  },
};
</script>
