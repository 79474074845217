<template>
  <div class="callback-loading">
    <h1>Laden...</h1>
  </div>
</template>

<script>
export default {
  name: "CallbackLoading",
};
</script>

<style lang="scss" scoped>
.callback-loading {
  max-width: 1200px;
  margin: 11rem auto 0 auto;
}
</style>
