<template>
  <callback-loading></callback-loading>
</template>

<script>
import user from "../services/userServices/userService";

export default {
  name: "AuthCallback",
  created() {
    user.doLoginRedirect();
  },
};
</script>
