//Vue plugin
//Translate js-strings with Crafts static message translations
import pick from "lodash/pick";
import { LOCAL_STORAGE_TRANSLATIONS } from "../constants/localStorage";

export default {
  //Initialize plugin
  async install(Vue) {
    //Get the translations
    // The filter is called before the API-call is complete and gives us a warning
    // We use the localstorage to store the translations, so we'll only have the warning the first time the user lands on a page. It also gives us instant translation from here.
    const storageTranslations = localStorage.getItem(
      LOCAL_STORAGE_TRANSLATIONS
    );
    let translations = null;

    // Get the translations from the store if they're there
    if (storageTranslations && storageTranslations !== "undefined") {
      translations = JSON.parse(storageTranslations);
    } else {
      // Get the translations from the API if they're not in storage
      translations = getTranslations();
      // Set the store
      localStorage.setItem(
        LOCAL_STORAGE_TRANSLATIONS,
        JSON.stringify(translations)
      );
    }

    //Add a filter of "translate". Works like this: {{ "translate me!" | t }}
    Vue.filter("t", (value) => {
      if (!value) return "";
      value = value.toString();

      //Pick the corresponding translation
      const filtered = pick(translations, value);

      //If there's a translation:
      if (Object.keys(filtered).length) {
        //Return translation
        return filtered[value];
      }
      //If there isn't a translation, return original value and warn developer
      if (process.env.ENVIRONMENT !== "production") {
        console.warn('"' + value + '" hasn’t been translated yet');
      }
      return value;
    });

    // Update the localStorage with the most current translations
    if (storageTranslations) {
      translations = await getTranslations();
      localStorage.setItem(
        LOCAL_STORAGE_TRANSLATIONS,
        JSON.stringify(translations)
      );
    }
  },
};

function getTranslations() {
  return window.dataStatic.translations;
}
