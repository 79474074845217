<template>
  <div ref="selectbox" class="select-box">
    <div class="value" @click="show()">
      {{ selected ? selected.name : title | t }}
      <i class="fas fa-chevron-down"></i>
    </div>
    <div v-if="open" class="options">
      <input
        v-if="limitType === 'input'"
        v-model="search"
        type="text"
        :placeholder="'Search...' | t"
      />
      <p v-if="search && optionsList.length === 0" class="no-search-result">
        {{ "Nothing found for" | t }} <strong>{{ search }}</strong>
      </p>
      <div
        v-for="(option, index) in optionsList"
        :key="index"
        class="option"
        :class="{ available: option.available, selected: option.selected }"
        @click="setOption(option)"
      >
        {{ getOptionName(option.name) }}
      </div>
      <p
        v-if="limitType === 'showMore' && options.length > limit"
        class="show-more link"
        @click="ignoreLimit = true"
      >
        Toon alles
      </p>
    </div>
  </div>
</template>

<script>
import selectFunctions from "./../mixins/selectFunctions.js";

export default {
  name: "SelectBox",
  mixins: [selectFunctions],
};
</script>
