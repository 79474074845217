<template>
  <div class="featured-grid">
    <component
      :is="item.type ? item.type + '-tile' : 'loading-tile'"
      v-for="(item, index) in gridList"
      :key="index"
      :item="item"
    />
    <button
      v-if="moreItemsAvailable"
      class="button--secondary load-more"
      :class="{ 'button--loading': products.loading || articles.loading }"
      @click="loadMore"
    >
      <i class="far fa-arrow-down"></i>{{ "Load more" | t }}
    </button>
  </div>
</template>

<script>
import Vue from "vue";
import api from "./../services/api";
import productService from "../services/productService";

const PRODUCT_ABBREVIATION = "p";
const ARTICLE_ABBREVIATION = "r"; //redactional
const ADVERTISEMENT_ABBREVIATION = "a";
const FEATURED_PAGE_SESSION_STORAGE = "ic_featured_page";

export default {
  name: "FeaturedGrid",
  props: {
    featuredGridOrder: {
      type: Array,
      required: true,
    },
    currentArticleId: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      products: {
        type: "product",
        letter: PRODUCT_ABBREVIATION,
        loading: true,
        response: null,
        loadMore: true,
        items: [],
        currentPage: 1,
        itemsTotal: 0,
        itemsUsed: 0,
        itemsNeeded: 0,
      },
      articles: {
        type: "article",
        letter: ARTICLE_ABBREVIATION,
        loading: true,
        response: null,
        loadMore: true,
        items: [],
        currentPage: 1,
        itemsTotal: 0,
        itemsUsed: 0,
        itemsNeeded: 0,
      },
      advertisements: {
        type: "advertisement",
        letter: ADVERTISEMENT_ABBREVIATION,
        items: [],
        itemsUsed: 0,
        itemsNeeded: 0,
      },
      gridList: [],
      amount_per_call: 10, //should be at least 9 to prevent weird errors
      page: 0,
      gridOrderPage: 0,
    };
  },
  computed: {
    moreItemsAvailable() {
      return (
        this.articles.itemsNeeded < this.articles.itemsTotal ||
        this.products.itemsNeeded < this.products.itemsTotal
      );
    },
    sessionStorageConst() {
      return FEATURED_PAGE_SESSION_STORAGE + window.location.pathname;
    },
  },
  mounted() {
    this.getList();
    const page = parseInt(sessionStorage.getItem(this.sessionStorageConst));
    Array.from({ length: page }, () => {
      return this.loadMore({ initial: true });
    });
  },
  methods: {
    async getProducts() {
      const config = {
        currentPage: this.products.currentPage,
        perPage: this.amount_per_call,
      };

      Vue.set(this.products, "loading", true);

      const response = await productService.getFeaturedProducts(config);

      if (response === false || response.products.total === 0) {
        return;
      }

      this.setItems(this.products, response.products, response.products.total);
    },
    getArticles() {
      Vue.set(this.articles, "loading", true);
      api
        .get(
          "/articleFeed?page=" +
            this.articles.currentPage +
            "&per_page=" +
            this.amount_per_call
        )
        .then((response) => {
          // api.get defaults to falseOnFailure=true
          if (response === false || response.meta.pagination.count === 0) {
            return;
          }

          this.setItems(
            this.articles,
            response,
            response.meta.pagination.total
          );
        });
    },
    getAdvertisements() {
      // Setup advertisements
      this.advertisements.items = new Array(
        this.advertisements.itemsNeeded
      ).fill({});
      this.updateList(this.advertisements);
    },
    setItems(type, response, total) {
      let items = response.data;
      if (type === this.articles)
        items = items.filter(
          (x) => String(x.id) !== String(this.currentArticleId)
        ); // Remove current article from equation

      Vue.set(type, "response", response);
      Vue.set(type, "items", type.items.concat(items));
      Vue.set(type, "loading", false);
      Vue.set(type, "itemsTotal", total);
      type.currentPage++;

      this.updateList(type);
    },
    updateList(type) {
      // Add type to items in array so when can select the right component later on
      let items = type.items;
      items = items.map((i) => (i = { ...i, type: type.type }));

      // Assign items to their position
      for (let i = 0, n = this.gridList.length; i < n; ++i) {
        if (this.gridList[i].toString().toLowerCase() === type.letter) {
          // If item not available, start again from first item
          if (!items[type.itemsUsed]) Vue.set(type, "itemsUsed", 0);

          // Replace letter with actual item
          Vue.set(this.gridList, i, items[type.itemsUsed]);
          type.itemsUsed++;
        }
      }
    },
    loadMore({ initial = false }) {
      this.page++;
      this.gridOrderPage++;
      this.getList();

      // Save current page in session
      if (!initial) {
        sessionStorage.setItem(this.sessionStorageConst, this.page);
      }
    },
    getList() {
      // If the next page isn't available use the first page again
      if (!this.featuredGridOrder[this.gridOrderPage]) this.gridOrderPage = 0;

      //Add items from the current page to the gridlist
      this.gridList = this.gridList.concat(
        this.featuredGridOrder[this.gridOrderPage]
      );

      // Set items needed
      Vue.set(
        this.products,
        "itemsNeeded",
        this.products.itemsNeeded +
          this.gridList.filter(
            (x) => x.toString().toLowerCase() === PRODUCT_ABBREVIATION
          ).length
      );
      Vue.set(
        this.articles,
        "itemsNeeded",
        this.articles.itemsNeeded +
          this.gridList.filter(
            (x) => x.toString().toLowerCase() === ARTICLE_ABBREVIATION
          ).length
      );
      Vue.set(
        this.advertisements,
        "itemsNeeded",
        this.advertisements.itemsNeeded +
          this.gridList.filter(
            (x) => x.toString().toLowerCase() === ADVERTISEMENT_ABBREVIATION
          ).length
      );

      this.itemCheck();
    },
    itemCheck() {
      // Check to see if another API-call is needed, or to just rebuild the list with available items
      if (
        this.products.items.length === 0 ||
        (this.products.itemsNeeded > this.products.items.length &&
          this.products.items.length !== this.products.itemsTotal)
      ) {
        this.getProducts();
      } else {
        this.updateList(this.products);
      }

      if (
        this.articles.items.length === 0 ||
        (this.articles.itemsNeeded > this.articles.items.length &&
          this.articles.items.length !== this.articles.itemsTotal)
      ) {
        this.getArticles();
      } else {
        this.updateList(this.articles);
      }

      if (this.advertisements.itemsNeeded > this.advertisements.itemsUsed) {
        this.getAdvertisements();
      }
    },
  },
};
</script>
