<template>
  <div class="pop-up pop-up--budget" :class="{ show }">
    <div class="pop-up__background" @click="closePopUp"></div>
    <div class="pop-up__content">
      <slot :open="openPopUp" :close="closePopUp"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "PopupWindow",
  props: {
    canClose: {
      required: false,
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      show: false,
    };
  },
  methods: {
    closePopUp() {
      if (this.canClose) {
        this.show = false;
      }
    },
    openPopUp() {
      this.show = true;
    },
  },
};
</script>
