<template>
  <a class="tile">
    <div class="tile__content">
      <h3 class="title-m">ADVERTISEMENT</h3>
    </div>
  </a>
</template>

<script>
export default {
  name: "AdvertisementTile",
};
</script>
