import api from "./api";

const productService = () => {
  const baseUrl = window.location.origin;

  const buildProductEndpoint = (
    endpointPath,
    currentPage = 1,
    perPage = 10,
    options = {}
  ) => {
    const query = new URL(endpointPath, baseUrl);

    query.searchParams.set("page", currentPage);
    query.searchParams.set("per_page", perPage);

    Object.keys(options).forEach((key) => {
      if (options[key]) query.searchParams.set(key, options[key]);
    });

    return query;
  };

  const getProducts = async ({ currentPage, perPage, options } = {}) => {
    const query = buildProductEndpoint(
      "/ic-api/products",
      currentPage,
      perPage,
      options
    );

    return await api.get(query.toString());
  };

  const getProductsByCategory = async ({
    category,
    currentPage,
    perPage,
    options,
  } = {}) => {
    if (!category) return;

    const query = buildProductEndpoint(
      "/ic-api/products",
      currentPage,
      perPage,
      options
    );

    query.searchParams.set("category", category);

    return await api.get(query.toString());
  };

  const getFeaturedProducts = async ({ currentPage, perPage } = {}) => {
    const query = buildProductEndpoint("/featured", currentPage, perPage);

    return await api.get(query.toString());
  };

  return {
    getProducts,
    getProductsByCategory,
    getFeaturedProducts,
  };
};

export default productService();
