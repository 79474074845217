export const buildDateTimeString = (date, timeslot) => {
  if (!date && !timeslot) {
    return null;
  }

  if (date && timeslot) {
    return `${date} ${timeslot}`;
  }

  return date;
};

export const isBeforeOrOnSameDate = (dateA, dateB) => {
  if (!(dateA instanceof Date) || !(dateB instanceof Date)) {
    return null;
  }

  return dateA <= dateB;
};

export default {
  buildDateTimeString,
  isBeforeOrOnSameDate,
};
