import apiClient from "../intoCultureApiClient";
import AuthorizationError from "../../errors/AuthorizationError";
import cookieHelper from "../../helpers/cookieHelper";

import {
  LOCAL_STORAGE_OAUTH_TOKEN,
  LOCAL_STORAGE_REDIRECT_URL,
} from "../../constants/localStorage";

import {
  SESSION_STORAGE_BUDGET_VALUE,
  SESSION_STORAGE_BUDGET_POPUP,
} from "../../constants/sessionStorage";

import {
  COOKIE_OAUTH_TOKEN,
  COOKIE_SESSION_TOKEN,
  COOKIE_ACTIVE_MEMBERSHIP,
} from "../../constants/cookies";

import { PATH_LOGIN_FORM } from "../../constants/paths";

const userService = () => {
  const clearUserData = async () => {
    localStorage.removeItem(LOCAL_STORAGE_OAUTH_TOKEN);
    localStorage.removeItem(LOCAL_STORAGE_REDIRECT_URL);
    sessionStorage.removeItem(SESSION_STORAGE_BUDGET_VALUE);
    sessionStorage.removeItem(SESSION_STORAGE_BUDGET_POPUP);
    cookieHelper.removeCookie(COOKIE_OAUTH_TOKEN);
    cookieHelper.removeCookie(COOKIE_SESSION_TOKEN);
    cookieHelper.removeCookie(COOKIE_ACTIVE_MEMBERSHIP);
  };

  const getBalance = () => {
    return;
  };

  const doLogin = async ({ returnTo }) => {
    try {
      if (returnTo) {
        localStorage.setItem(LOCAL_STORAGE_REDIRECT_URL, returnTo);
      }

      location.assign(PATH_LOGIN_FORM);
    } catch (err) {
      console.error(err.message);
    }
  };

  const doLogout = async () => {
    await clearUserData();
  };

  const doLoginRedirect = () => {
    const returnTo = localStorage.getItem(LOCAL_STORAGE_REDIRECT_URL);
    localStorage.removeItem(LOCAL_STORAGE_REDIRECT_URL);
    window.location.assign(returnTo || "/");
  };

  const getUser = async (force_fetch = false) => {
    const user = cookieHelper.getCookieValue("cjp_user");
    if (user && force_fetch === false) {
      // Assume logged in.
      return user;
    }
    const response = await apiClient.getAuthUser();
    // TODO: Until proper errors are thrown by apiClient, this is the way
    if (response.error || !response.email) {
      throw new AuthorizationError(response.error);
    } else {
      cookieHelper.setCookie("cjp_user", response.email);
      return response.email;
    }
  };

  const isAuthorized = async () => {
    try {
      if (!(await getUser())) {
        return false;
      }

      return true;
    } catch {
      return false;
    }
  };

  /**
   * Simple check to assume if a user has been authenticated
   *
   * @returns Promise<Boolean>
   */
  const isAuthenticated = async () => {
    return await isAuthorized();
  };

  const hasActiveMembership = async () => {
    const membership = cookieHelper.getCookieValue(COOKIE_ACTIVE_MEMBERSHIP);
    if (membership && membership !== "false") {
      return membership;
    } else {
      const response = await apiClient.getActiveMembership();
      cookieHelper.removeCookie(COOKIE_ACTIVE_MEMBERSHIP);
      if (response.success) {
        for (const subscription of response.data) {
          const startDate = new Date(subscription.startDate);
          const endDate = new Date(subscription.endDate);
          const now = new Date();
          if (
            subscription.status === "active" &&
            startDate < now &&
            endDate > now
          ) {
            cookieHelper.setCookie(COOKIE_ACTIVE_MEMBERSHIP, subscription.code);
            return subscription.code;
          }
        }
      }
      return false;
    }
  };

  return {
    clearUserData,
    doLogin,
    doLoginRedirect,
    doLogout,
    getUser,
    getBalance,
    isAuthorized,
    isAuthenticated,
    hasActiveMembership,
  };
};

export default userService();
