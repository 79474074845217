<template>
  <div :class="`sticky-bar--${position}`">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "StickyBar",
  props: {
    position: {
      type: String,
      required: false,
      default: "bottom",
    },
  },
};
</script>
