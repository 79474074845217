const buildQueryStringParam = (key, value) => {
  if (value instanceof Array) {
    return buildQueryStringParam(key, JSON.stringify(value));
  }

  return encodeURIComponent(key) + "=" + encodeURIComponent(value);
};

const buildQueryString = (params) => {
  return Object.keys(params)
    .map((key) => buildQueryStringParam(key, params[key]))
    .join("&");
};

const intoCultureApiClientEndpoints = {
  authUser: () => {
    const hostnameSplit = window.location.hostname.split(".");
    hostnameSplit[0] = "oauth2";
    let hostname = hostnameSplit.join(".");
    if (hostnameSplit.length < 3) {
      hostname = "oauth2.cjp.nl";
    }
    return window.location.protocol + "//" + hostname + "/api/user-oauth";
  },
  categories: "/ic-api/categories",
  order: (data) => {
    const queryString = buildQueryString(data);
    return `/ic-api/order?${queryString}`;
  },
  orders: "/ic-api/orders",
  idealIssuers: "/ic-api/issuers",
  products: "/ic-api/products",
  product: (slug) => `/ic-api/products/${slug}`,
  productAvailability: (slug, data) => {
    const queryString = buildQueryString(data);
    return `/ic-api/products/${slug}/availability?${queryString}`;
  },
  tickets: (data) => {
    const queryString = buildQueryString(data);
    return `/ic-download/tickets?${queryString}`;
  },
  featured: "/ic-api/featured",
  vendors: "/ic-api/vendors",
  vendor: (vendorSlug) => `/ic-api/vendors/${vendorSlug}`,
  activeMembership: () => {
    const hostnameSplit = window.location.hostname.split(".");
    hostnameSplit[0] = "app";
    let hostname = hostnameSplit.join(".");
    if (hostnameSplit.length < 3) {
      hostname = "app.cjp.nl";
    }
    return (
      window.location.protocol + "//" + hostname + "/api/profile/subscriptions"
    );
  },
};

export default intoCultureApiClientEndpoints;
