<template>
  <div class="navigation-click-area" @click="toggleMenu()"></div>
</template>

<script>
import EventBus from "./../event-bus";
export default {
  name: "NavigationClickArea",
  methods: {
    toggleMenu() {
      EventBus.$emit("toggleMenu");
    },
  },
};
</script>
