<template>
  <a ref="tile" :href="item.url" class="tile" :class="item.type">
    <h3 v-if="item.name" class="title-m">{{ item.name }}</h3>
    <p class="uri">/{{ item.uri }}</p>
    <i class="arrow fas fa-long-arrow-alt-right"></i>
  </a>
</template>

<script>
export default {
  name: "PageTile",
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
};
</script>
