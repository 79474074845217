<template>
  <div class="faq-item" :class="{ open: open }" @click="toggleItem">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "FaqItem",
  data() {
    return {
      open: false,
    };
  },
  methods: {
    toggleItem() {
      this.open ^= true;
    },
  },
};
</script>
