<template>
  <div class="featured-grid">
    <component
      :is="item.type"
      v-for="(item, index) in list"
      :key="index"
      :item="item"
    />
    <button
      v-if="articleLoadMore"
      class="button--secondary load-more"
      :class="{ 'button--loading': articleLoading }"
      @click="loadMore"
    >
      <i class="far fa-arrow-down"></i>{{ "Load more" | t }}
    </button>
  </div>
</template>

<script>
import api from "./../services/api";

export default {
  name: "ArticleGrid",
  data() {
    return {
      articleLoading: true,
      articleData: null,
      articleLoadMore: true,
      articles: [],
      amount: 9,
      page: 1,
    };
  },
  computed: {
    list() {
      //While loading
      if (this.articles.length < 1) {
        return Array(this.amount).fill({ type: "loading-tile" });
      }

      //After loading
      const articles = this.articles;

      //Add type to items in array so when can select the right component later on
      return articles.map((i) => ({ ...i, type: "article-tile" }));
    },
  },
  mounted() {
    this.getArticles();
  },
  methods: {
    getArticles() {
      this.articleLoading = true;
      api
        .get("/articleFeed?page=" + this.page + "&per_page=" + this.amount)
        .then((response) => {
          // api.get defaults to falseOnFailure=true
          if (response === false || response.meta.pagination.count === 0) {
            return;
          }

          this.articleData = response;
          this.articles = this.articles.concat(response.data);
          this.articleLoading = false;
          this.checkLoadMore();
        });
    },
    loadMore() {
      this.page++;
      this.getArticles();
    },
    checkLoadMore() {
      if (this.articleData) {
        const available = this.articleData.meta.pagination.total;
        const currentAmount = this.articles.length;
        if (available <= currentAmount) {
          this.articleLoadMore = false;
        }
      }
    },
  },
};
</script>
