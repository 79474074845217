<template>
  <button class="button--robin" @click="openRobin">{{ text }}</button>
</template>

<script>
export default {
  name: "RobinButton",
  props: {
    text: {
      type: String,
      required: true,
    },
  },
  methods: {
    openRobin() {
      __robin.show();
    },
  },
};
</script>
