<template>
  <button
    class="nav-button--search"
    :aria-label="'Search' | t"
    @click="toggleSearch()"
  >
    <i v-if="!search" class="fas fa-search"></i>
    <i v-else class="fas fa-times"></i>
  </button>
</template>

<script>
import EventBus from "./../event-bus";

export default {
  name: "NavigationButtonSearch",
  data() {
    return {
      search: 0,
    };
  },
  mounted() {
    //Keep open the option of reacting to a toggle elsewhere
    EventBus.$on("toggleSearch", () => {
      this.search ^= true;
    });
  },
  methods: {
    toggleSearch() {
      EventBus.$emit("toggleSearch");
    },
  },
};
</script>
