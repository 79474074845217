<template>
  <div class="category-slider">
    <div ref="slider" class="slider">
      <div class="spacer"></div>
      <slot></slot>
    </div>
    <button
      v-if="!(currentScrollLeft === 0 || currentItemIndex === 0)"
      class="button button--prev"
      @click="goCarousel('previous')"
    >
      <i class="fas fa-chevron-left"></i>
    </button>
    <button
      v-if="currentScrollRight !== 0 && next"
      class="button button--next"
      @click="goCarousel('next')"
    >
      <i class="fas fa-chevron-right"></i>
    </button>
  </div>
</template>

<script>
import debounce from "lodash/debounce";

export default {
  name: "CategorySlider",
  data() {
    return {
      carouselItems: [],
      currentItemIndex: 0,
      currentScrollLeft: 0,
      currentScrollRight: null,
      totalWidth: 0,
      next: false,
      slider: null,
    };
  },
  mounted() {
    this.slider = this.$refs.slider;
    this.getCarouselPositions();
    this.slider.addEventListener("scroll", debounce(this.onScroll, 200));
    window.addEventListener("resize", () => {
      this.getCarouselPositions();
    });
  },
  methods: {
    onScroll() {
      const scrollLeft = this.slider.scrollLeft;
      this.currentScrollLeft = scrollLeft;
      this.currentScrollRight =
        this.totalWidth - this.slider.offsetWidth - this.currentScrollLeft;

      const items = this.carouselItems;

      // Get all offsets
      const offsets = Object.keys(items).map(function(k) {
        return items[k].offset;
      });

      // Get the nearest offset to current position
      const currentOffset = offsets.reduce(function(prev, curr) {
        return Math.abs(curr - scrollLeft) < Math.abs(prev - scrollLeft)
          ? curr
          : prev;
      });

      // Get object that corresponds with the nearest offset
      const current = items.filter((item) => {
        return item.offset === currentOffset;
      });

      // Get and set the index from the current item
      this.currentItemIndex = current[0] ? parseInt(current[0].index) : null;
    },
    getCarouselPositions() {
      const items = this.slider.querySelectorAll("div");
      this.carouselItems = [];

      //Put all positions into an object
      for (let i = 0; i < items.length; i++) {
        const element = items[i];
        if (element.offsetWidth === 0) continue;
        this.carouselItems.push({
          index: i,
          offset: element.offsetLeft,
          width: element.offsetWidth,
        });

        // Scroll to element with active class
        const elementClass = element.className;
        if (elementClass.includes("active")) {
          this.slider.scrollTo({
            left: element.offsetLeft,
          });
        }
      }

      //Get sum of all item widths
      this.totalWidth = this.carouselItems
        .map((a) => a.width)
        .reduce((sum, val) => sum + val, 0);

      //Determine if the next button should be visible
      this.next = this.totalWidth > this.slider.offsetWidth;
    },
    goCarousel(direction) {
      if (direction === "next") {
        this.currentItemIndex++;
      } else if (direction === "previous") {
        this.currentItemIndex--;
      }

      const currentItem = this.carouselItems.filter((item) => {
        return item.index === this.currentItemIndex;
      });

      if (!currentItem[0]) {
        return;
      }

      //Slide to new position
      this.slider.scrollTo({
        left: currentItem[0].offset,
        behavior: "smooth",
      });
    },
  },
};
</script>
