<!-- eslint-disable vue/no-v-html -->
<template>
  <div v-if="form" class="form">
    <form :id="handle" @submit="submit">
      <div
        v-for="(field, index) in fields"
        :key="index"
        class="field"
        :class="'field--' + field.type"
        v-html="parseField(field.field)"
      ></div>
    </form>

    <div v-if="status === 'succes'" class="succes-message">
      {{ succesMessage || "Form submitted" | t }}
    </div>

    <div v-if="status === 'error'" class="error-message">
      {{ errorMessage || "Something went wrong" | t }}
    </div>
  </div>
</template>

<script>
import axios from "axios";
import api from "./../services/api";

export default {
  name: "FormWrapper",
  props: {
    handle: { type: String, required: true },
    hash: { type: String, required: true },
    csrf: { type: Object, required: true },
    formPayload: { type: Object, required: true },
    honeypot: { type: Object, required: true },
  },
  data() {
    return {
      form: null,
      loading: false,
      extraPostUrl: null,
      succesMessage: null,
      errorMessage: null,
      status: null,
    };
  },
  computed: {
    fields() {
      if (!this.form) return;
      return this.form.fields;
    },
  },
  mounted() {
    this.getForm();
  },
  methods: {
    parseField(field) {
      field = JSON.parse(JSON.stringify(field).replace("data-", ""));
      if (field.includes("checkbox")) {
        const doc = new DOMParser().parseFromString(field, "text/html");
        const selectBox = doc.querySelector("input[type='checkbox']");

        const newField = document.createElement("div");
        newField.classList.add("checkbox");
        newField.innerHTML = selectBox.outerHTML;

        const checkMark = document.createElement("div");
        checkMark.classList.add("checkmark");
        newField.append(checkMark);

        selectBox.replaceWith(newField);
        return doc.body.firstChild.outerHTML;
      }
      return field;
    },
    getForm() {
      api.get("/form/" + this.handle).then((response) => {
        this.form = response;
        const layoutJson = JSON.parse(this.form.layoutJson);
        const properties = layoutJson.composer.properties;
        if (!properties) {
          return;
        }

        this.extraPostUrl = properties.form.extraPostUrl;
        this.succesMessage = properties.validation.successMessage;
        this.errorMessage = properties.validation.errorMessage;
      });
    },
    submit(evt) {
      this.status = null;
      this.loading = true;
      if (this.extraPostUrl) {
        this.doExtraPost(evt);
      }

      const form = evt.target;
      const data = new FormData(form);
      const request = new XMLHttpRequest();

      data.append("action", "freeform/api/form");
      data.append("formHash", this.hash);
      data.append(this.csrf.name, this.csrf.hash);
      data.append(this.honeypot.name, this.honeypot.hash);
      data.append(this.formPayload.name, this.formPayload.hash);

      request.open("POST", window.location.href, true);
      request.setRequestHeader("Cache-Control", "no-cache");
      request.setRequestHeader("X-Requested-With", "XMLHttpRequest");
      request.setRequestHeader("HTTP_X_REQUESTED_WITH", "XMLHttpRequest");

      request.onload = () => {
        if (request.status === 200) {
          const response = JSON.parse(request.response);
          const { success, finished, errors, formErrors } = response;
          if (success && finished) {
            form.reset();
            if (this.status !== "error") this.status = "succes";
          } else if (errors || formErrors) {
            this.status = "error";
            console.error(errors || formErrors);
          }
        } else {
          this.status = "error";
          console.error(request);
        }

        this.loading = false;
      };

      // Send the request
      request.send(data);

      evt.stopPropagation();
      evt.preventDefault();
      return false;
    },
    doExtraPost(evt) {
      const form = evt.target;
      const data = new FormData(form);

      axios({
        method: "post",
        url: this.extraPostUrl,
        data,
      })
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            if (response.data.status === "ok") {
              if (this.status !== "error") this.status = "succes";
            }
          } else {
            this.status = "error";
            console.error(response.data);
          }
        })
        .catch((error) => {
          this.status = "error";
          console.error(error.response.data);
        });

      evt.stopPropagation();
      evt.preventDefault();
    },
  },
};
</script>
