export const LOCAL_STORAGE_ORDER = "ic_order";
export const LOCAL_STORAGE_ORDER_ID = "ic_order_id";
export const LOCAL_STORAGE_REDIRECT_URL = "ic_redirect";
export const LOCAL_STORAGE_TRANSLATIONS = "ic_translations";
export const LOCAL_STORAGE_OAUTH_TOKEN = "oauth_token"; // Defined by CJP
export const LOCAL_STORAGE_GLOBALS = "ic_globals";
export const LOCAL_SESSION_ORDER = "ic_order";

export default {
  LOCAL_STORAGE_ORDER,
  LOCAL_STORAGE_ORDER_ID,
  LOCAL_STORAGE_REDIRECT_URL,
  LOCAL_STORAGE_TRANSLATIONS,
  LOCAL_STORAGE_OAUTH_TOKEN,
  LOCAL_STORAGE_GLOBALS,
};
