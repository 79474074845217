<template>
  <div ref="selectbox" class="select-list">
    <p class="title">{{ title | t }}</p>
    <input
      v-if="limitType === 'input'"
      v-model="search"
      type="text"
      :placeholder="'Search...' | t"
    />
    <p v-if="search && optionsList.length === 0" class="no-search-result">
      {{ "Nothing found for" | t }} <strong>{{ search }}</strong>
    </p>
    <div
      v-for="(option, index) in optionsList"
      :key="index"
      class="option"
      :class="{ available: option.available, selected: option.selected }"
      @click="setOption(option)"
    >
      <div class="checkbox" :class="{ selected: option.selected }"></div>
      {{ getOptionName(option.name) }}
    </div>
    <p
      v-if="limitType === 'showMore' && options.length > limit"
      class="show-more link"
      @click="ignoreLimit = true"
    >
      {{ "Show all" | t }}
    </p>
  </div>
</template>

<script>
import selectFunctions from "./../mixins/selectFunctions.js";

export default {
  name: "SelectList",
  mixins: [selectFunctions],
};
</script>
