<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="product-list">
    <div v-if="products && products.length === 0" class="product-list__empty">
      <i class="fas fa-search"></i>
      <h3 v-if="searchQuery" class="title-m--alt">
        {{ "Results for" | t }} &ldquo;{{ searchQuery }}&rdquo;
      </h3>
      <p v-if="searchQuery" v-text="noResult"></p>
      <h3 v-else class="title-m--alt">{{ "No result" | t }}</h3>
    </div>
    <component
      :is="item.type"
      v-for="(item, index) in productList"
      v-else
      :key="index"
      :item="item"
    />
  </div>
</template>

<script>
import ProductTile from "./ProductTile.vue";

export default {
  name: "ProductList",
  components: { ProductTile },
  props: {
    products: {
      type: Array,
      required: false,
      default: null,
    },
    type: {
      type: String,
      required: false,
      default: "product",
    },
  },
  data() {
    return {
      searchQuery: null,
    };
  },
  computed: {
    productList() {
      //While loading
      if (!this.products || this.products.length < 1) {
        return Array(9).fill({ type: "loading-tile" });
      }

      //After loading
      //Add type to items in array so when can select the right component later on
      return this.products.map(
        (i) => (i = { ...i, type: this.type + "-tile" })
      );
    },
    noResult() {
      if (!this.searchQuery) return;
      const sorry = this.$options.filters.t(
        "Sorry, we could find anything for"
      );
      const anotherSearch = this.$options.filters.t(
        "Do another search or look at our complete collection"
      );
      const message =
        sorry +
        " <strong>" +
        this.searchQuery +
        "</strong>. <br/><br/> " +
        anotherSearch;
      return message;
    },
  },
  mounted() {
    this.checkSearch();
  },
  methods: {
    checkSearch() {
      const urlParams = new URLSearchParams(window.location.search);
      this.searchQuery = urlParams.get("q");
    },
  },
};
</script>
