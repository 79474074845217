<template>
  <button class="button--primary" @click="openPopup">
    <i class="icon fas fa-shopping-basket"></i>{{ "Order now" | t }}
  </button>
</template>

<script>
import user from "../services/userServices/userService";
import EventBus from "./../event-bus";

export default {
  name: "PopupSpendBudgetButton",
  data() {
    return {
      isLoggedIn: false,
    };
  },
  async mounted() {
    EventBus.$on("loggedIn", (event) => {
      if (event === false) {
        this.isLoggedIn = false;
      }
    });

    try {
      this.isLoggedIn = await user.isAuthenticated();
    } catch {
      this.isLoggedIn = false;
    }
  },
  methods: {
    openPopup() {
      if (this.isLoggedIn) {
        return EventBus.$emit("openPopupSpendBudget", false);
      }
      return (window.location.href = "/login");
    },
  },
};
</script>
