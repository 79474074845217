<template>
  <div
    class="navigation"
    :class="{
      hidden: hidden,
      'navigation--search-open': searchOpen,
      'navigation--top-position': onTopPosition,
    }"
  >
    <slot></slot>
  </div>
</template>

<script>
import EventBus from "./../event-bus";

export default {
  name: "MainNavigation",
  props: {
    searchDefault: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      scrollPos: 0,
      highestScrollPos: 0,
      lowestScrollPos: 0,
      hidden: false,
      searchOpen: false,
      onTopPosition: true,
    };
  },
  created() {
    this.setScroll();
  },
  mounted() {
    if (this.searchDefault === "open") this.toggleSearch();

    EventBus.$on("toggleSearch", () => {
      this.toggleSearch(true);
    });
  },
  methods: {
    setScroll() {
      window.addEventListener("scroll", this.handleScroll);
    },
    handleScroll() {
      if (window.scrollY > this.scrollPos) {
        //Scrolling down
        if (window.scrollY - this.highestScrollPos > 50) {
          //Als de gebruiker naar beneden heeft gescrolled: hide het menu.
          this.hidden = true;
        }

        //Reset lowest scroll position
        this.lowestScrollPos = 0;
      } else {
        //Scrolling up
        if (window.scrollY > this.lowestScrollPos) {
          this.lowestScrollPos = window.scrollY;
        }
        if (this.lowestScrollPos - window.scrollY > 100) {
          //Als de gebruiker 100px naar boven heeft gescrolled: show het menu.
          this.hidden = false;
        }

        //Reset highest scroll position
        this.highestScrollPos = 0;
        this.highestScrollPos = window.scrollY;
      }
      this.scrollPos = window.scrollY;

      this.onTopPosition = false; //reset top
      if (window.scrollY < 50) {
        this.hidden = false;
        this.onTopPosition = true;
      }

      if (!this.hidden) {
        document.body.classList.add("menu-visible");
      } else {
        document.body.classList.remove("menu-visible");
      }
    },
    toggleSearch(transition) {
      this.searchOpen ^= true;
      if (this.searchOpen) {
        if (transition) document.body.classList.add("search-transition");
        document.body.classList.add("search-open");
      } else {
        document.body.classList.remove("search-open");
        if (transition) {
          //Wait for transition to end
          setTimeout(function() {
            document.body.classList.remove("search-transition");
          }, 500);
        }
      }
    },
  },
};
</script>
