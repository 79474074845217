import userService from "./userService";
import cjpUserService from "./cjpUserService";

/**
 * @returns {object} userService
 */
const getUserService = () => {
  // return cjpUserService for CJP environments
  if ("cjp" === process.env.VUE_APP_NAME) {
    return cjpUserService;
  }

  return userService;
};

export default getUserService();
