<template>
  <product-list v-if="products" :products="productList.data" />
</template>

<script>
import productService from "../services/productService";
import ProductList from "./ProductList.vue";

export default {
  name: "RelatedProducts",
  components: { ProductList },
  props: {
    category: {
      type: String,
      required: true,
      default: null,
    },
    currentId: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      products: null,
      options: {},
      loading: true,
      per_page: 10,
    };
  },
  computed: {
    productList() {
      // Remove current item from related list
      const productsWithoutCurrentItem = this.products.data.filter(
        (x) => String(x.id) !== String(this.currentId)
      );
      return { data: productsWithoutCurrentItem.slice(0, 9) }; // Make object interpretable for ProductList component
    },
  },
  async mounted() {
    this.loading = true;
    await this.getProductsByCategory();
    this.loading = false;
  },
  methods: {
    async getProductsByCategory() {
      const config = {
        category: this.category,
        perPage: this.perPage,
      };

      const response = await productService.getProductsByCategory(config);

      if (!response) {
        return;
      }

      this.products = response.products;
    },
  },
};
</script>
