<template>
  <a :href="href" class="tile" :class="item.type + '-tile'">
    <vue-image :srcset="item.images.desktop" :title="item.name"></vue-image>
    <label v-if="item.discount_label" class="discount-label">{{
      item.discount_label
    }}</label>
    <div class="tile__content">
      <p v-if="hasCategory" class="category-label">
        {{ item.categories[0].name || item.categories[0] }}
      </p>
      <h3 v-if="item.name" class="title-m">{{ item.name }}</h3>
      <p v-if="cities" class="city">
        <i class="icon fas fa-map-marker-alt"></i>{{ cities }}
      </p>
    </div>
  </a>
</template>

<script>
import VueImage from "./VueImage.vue";

export default {
  name: "ProductTile",
  components: { VueImage },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    slugProducts() {
      return window.dataStatic.slug.products;
    },
    hasCategory() {
      return this.item.categories && this.item.categories[0];
    },
    href() {
      if (this.hasCategory && this.item.categories[0].name) {
        // From api
        return (
          "/" +
          this.slugProducts +
          "/" +
          this.item.categories[0].name.toLowerCase() +
          "/" +
          this.item.slug
        );
      } else if (this.hasCategory && this.item.categories[0]) {
        // From aloglia
        return (
          "/" +
          this.slugProducts +
          "/" +
          this.item.categories[0].toLowerCase() +
          "/" +
          this.item.slug
        );
      } else {
        return "/" + this.slugProducts + "/" + this.item.slug;
      }
    },
    cities() {
      const cities = this.item.metadata.city || this.item.metadata.plaats;
      if (!cities) {
        return null;
      }

      if (cities.length > 1) {
        return this.$options.filters.t("Multiple locations");
      }
      return cities[0];
    },
  },
};
</script>
