<template>
  <div class="filter-page">
    <category-filters-mobile
      v-if="products"
      :data="data"
      :category="category"
      @setOption="setOption"
    />
    <div class="grid-container">
      <category-filters-desk v-if="products" :data="data" />
      <product-list v-if="products" :products="products.data" />
      <button
        v-if="loadMoreVisible"
        class="button--secondary load-more"
        :class="{ 'button--loading': loading }"
        @click="loadMore"
      >
        <i class="far fa-arrow-down"></i>{{ "Load more" | t }}
      </button>
    </div>
  </div>
</template>

<script>
import productService from "../services/productService";
import EventBus from "./../event-bus";

export default {
  name: "CategoryPage",
  props: {
    category: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      data: null,
      products: null,
      filters: [],
      options: {},
      loading: true,
      per_page: 24,
      currentPage: 1,
    };
  },
  computed: {
    loadMoreVisible() {
      return this.products && this.products.next_page_url;
    },
  },
  mounted() {
    this.getParameters();
    EventBus.$on("setCategoryOption", this.setOption);
  },
  methods: {
    async setOption(option) {
      this.options[option.type] = option.value;

      this.loading = true;
      await this.getProductsByCategory();
      this.loading = false;
    },
    async getProductsByCategory({ initial = false, loadMore = false } = {}) {
      const config = {
        category: this.category,
        currentPage: this.currentPage,
        perPage: this.per_page,
        options: this.options,
      };

      // don't run function on page load (it will remove the parameters)
      if (!initial) this.setParameters();

      const response = await productService.getProductsByCategory(config);

      if (response === false || response.products.total === 0) {
        return;
      }

      const products = response.products;

      // On loadMore, add new products to productlist
      if (loadMore) {
        products.data = this.products.data.concat(response.products.data);
      }

      this.data = response;
      this.products = products;
      this.filters = this.data.filters;
    },
    setParameters() {
      const url = new URL("", window.location.href);

      Object.keys(this.options).forEach((key) => {
        url.searchParams.set(key, this.options[key]);
      });

      window.history.replaceState(null, null, url.toString());
    },
    async getParameters() {
      // add parameters to options object
      const urlParams = new URLSearchParams(window.location.search);
      for (let i = 0; i < this.filters.length; i++) {
        const type = this.filters[i].type;
        const param = urlParams.get(type);
        if (param) this.options[type] = param;
      }

      // run initial get products
      this.loading = true;
      await this.getProductsByCategory({ initial: true });
      this.loading = false;
    },
    async loadMore() {
      if (!this.products.next_page_url) return;
      this.currentPage++;

      this.loading = true;
      await this.getProductsByCategory({ loadMore: true });
      this.loading = false;
    },
  },
};
</script>
