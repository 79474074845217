<template>
  <picture v-if="srcset" class="image">
    <source
      v-if="width && srcsetWebpResult"
      :srcset="srcsetWebpResult"
      :sizes="width"
      type="image/webp"
    />
    <source
      v-if="width && srcsetResult"
      :srcset="srcsetResult"
      :sizes="width"
    />
    <img
      :src="image"
      :alt="title"
      :width="width"
      :height="height"
      loading="lazy"
      decoding="async"
    />
  </picture>
</template>

<script>
export default {
  name: "VueImage",
  props: {
    srcset: {
      type: Array,
      required: false,
      default: () => [],
    },
    srcsetWebp: {
      type: Array,
      required: false,
      default: null,
    },
    placeholder: {
      type: String,
      required: false,
      default: null,
    },
    title: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      width: null,
      height: null,
    };
  },
  computed: {
    image() {
      if (this.placeholder) return this.placeholder;
      return this.srcset[this.srcset.length - 1];
    },
    srcsetResult() {
      return this.checkSrcset(this.srcset);
    },
    srcsetWebpResult() {
      return this.checkSrcset(this.srcsetWebp);
    },
  },
  mounted() {
    this.width = this.$el.offsetWidth + "px";
    this.height = this.$el.offsetHeight + "px";
  },
  methods: {
    checkSrcset(images) {
      if (!images) return;

      // Check if srcset has been provided with "w" values
      const result = images.filter(
        (image) => image.substring(image.length - 1) === "w"
      );
      if (result.length) return images;

      // Add "w" to value if they're not there.
      return images.map((image) => {
        const size = image.split("_");
        return image + " " + size[size.length - 2] + "w";
      });
    },
  },
};
</script>
