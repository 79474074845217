<template>
  <div class="pop-up" :class="{ show }">
    <div class="pop-up__background" @click="close"></div>
    <div class="pop-up__content">
      <i class="close fal fa-times" @click="close"></i>
      <slot></slot>
      <img src="/assets/confetti.png" class="emoticon" />
      <h2 class="title title-xl">
        Gefeliciteerd<br />
        met je CJP!
      </h2>
      <p>
        Yes, je hebt je CJP-account geactiveerd! Check wat je er nu mee kunt of
        bekijk hier alle kortingen van CJP
      </p>
      <div class="buttons">
        <button class="button--secondary" @click="close">
          Check alle deals
        </button>
        <button
          class="button--primary"
          @click="goTo('https://www.cjp.nl/blogs/pak-die-cjp')"
        >
          Wat kan ik met CJP?
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PopUp",
  props: {
    canClose: {
      required: false,
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      show: false,
    };
  },
  mounted() {
    this.checkParams();
  },
  methods: {
    checkParams() {
      const params = new URLSearchParams(window.location.search);
      if (params.has("registered")) {
        this.show = true;
      }
    },
    removeParams() {
      const params = new URLSearchParams(window.location.search);
      params.delete("registered");
      const newParams = new URLSearchParams(params).toString();
      const newUrl = newParams
        ? window.location.origin + "?" + newParams
        : window.location.origin;
      history.pushState({}, null, newUrl);
    },
    close() {
      if (this.canClose) {
        this.show = false;
        this.removeParams();
      }
    },
    goTo(location) {
      window.location.href = location;
    },
  },
};
</script>
