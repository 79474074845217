<template>
  <div class="all-products container">
    <h2 class="title-l">{{ title }}</h2>
    <product-list v-if="products" :products="products" />
    <button
      v-if="loadMoreVisible"
      class="button--secondary load-more"
      :class="{ 'button--loading': loading }"
      @click="loadMore"
    >
      <i class="far fa-arrow-down"></i>{{ "Load more" | t }}
    </button>
  </div>
</template>

<script>
import productService from "../services/productService";

export default {
  name: "AllProducts",
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      data: null,
      products: null,
      per_page: 36,
      loading: true,
      currentPage: 1,
      total: 0,
    };
  },
  computed: {
    loadMoreVisible() {
      return this.products && this.products.length !== this.total;
    },
  },
  mounted() {
    this.getItems({ initial: true });
  },
  methods: {
    async getItems({ loadMore = false } = {}) {
      const config = {
        currentPage: this.currentPage,
        perPage: this.per_page,
      };

      this.loading = true;

      const response = await productService.getProducts(config);

      if (response === false || response.products.total === 0) {
        this.loading = false;
        return;
      }

      let products;
      products = response.products.data;
      this.total = response.products.total;

      // On loadMore, add new products to productlist
      if (loadMore) {
        products = this.products.concat(response.products.data);
      }

      this.products = products;
      this.$emit("total", this.total);

      this.loading = false;
    },
    loadMore() {
      if (!this.loadMoreVisible) return;
      this.currentPage++;
      this.getItems({ loadMore: true });
    },
  },
};
</script>
