<!-- eslint-disable vue/no-v-html -->
<template>
  <transition name="alert-slide-fade">
    <div v-if="localMessage" class="alert__container grid-container">
      <div class="alert" :class="`is-${localType}`">
        <div class="alert__message" v-html="localMessage"></div>
        <button class="alert__btn-close" @click="localMessage = null">
          &times;
        </button>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "AlertMessage",
  props: {
    message: {
      type: String,
      required: false,
      default: null,
    },
    type: {
      type: String,
      required: false,
      default: "info",
      validator(value) {
        return ["info", "success", "warning", "danger"].indexOf(value) !== -1;
      },
    },
  },
  data() {
    return {
      localMessage: null,
      localType: null,
    };
  },
  created() {
    this.localType = this.type;
    this.localMessage = this.message || null;
  },
  methods: {},
};
</script>
