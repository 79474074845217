<template>
  <popup-window v-slot="scope" ref="popupWindow">
    <h2 class="title title-l">Neem contact op met de organisatie</h2>
    <p>
      De organisatie zal u verder helpen om via het Cultuurkaart-platform een
      activiteit te organiseren.
      <br /><br />
      Vermeld hierbij, indien beschikbaar, het CJP-schoolnummer ({{
        allSchoolNumber.join(" of ")
      }}). Dit nummer kunt u ook vinden in het schoolaccount op
      <a href="https://scholen.cjp.nl">scholen.cjp.nl</a>.
    </p>
    <h2>{{ vendorName }}</h2>
    <p>{{ vendorDescription }}</p>
    <h2>Contactgegevens</h2>
    <p>{{ vendorContactDetails }}</p>
    <div class="buttons">
      <button v-if="vendorEmail !== ''" class="button--primary" @click="mailTo">
        <i class="icon fas fa-envelope"></i>{{ "Order now" | t }}
      </button>
      <button class="button--secondary" @click="scope.close">
        Sluiten
      </button>
    </div>
  </popup-window>
</template>

<script>
import EventBus from "./../event-bus";

export default {
  name: "PopupEducationServiceRedirect",
  props: {},
  data() {
    return {
      allSchoolNumber: [],
      vendorName: "",
      vendorDescription: "",
      vendorContactDetails: "",
      vendorEmail: "",
      budgetholderName: "",
    };
  },
  mounted() {
    EventBus.$on("openPopupEducationServiceRedirect", async () => {
      await this.fetchSchoolDetails();
      await this.parseVendorDetails();
      this.$refs.popupWindow.openPopUp();
    });
  },
  methods: {
    mailTo() {
      const email = this.vendorEmail;
      const subject =
        "Aanvragen activiteit CJP.nl " + this.vendorName.substring(5);
      const body =
        "Beste heer/mevrouw,\n\nKunt u mij helpen om via het Cultuurkaart-platform een activiteit te organiseren? \n\nGegevens over de groep studenten en wensen m.b.t. de activiteit: \n[graag aanvullen] \n\nOns cjp-schoolnummer is " +
        this.allSchoolNumber.join(" of ") +
        ".\n\nMet vriendelijke groet,\n\n" +
        this.budgetholderName;

      const mailtoLink =
        "mailto:" +
        email +
        "?subject=" +
        encodeURIComponent(subject) +
        "&body=" +
        encodeURIComponent(body);

      window.location.href = mailtoLink;
    },
    parseVendorDetails() {
      this.vendorName = document.querySelector(
        ".product-venue__content .title-s"
      )?.innerText;
      this.vendorDescription = document.querySelector(
        ".product-venue__content p"
      )?.innerText;
      this.vendorContactDetails = document.querySelector(
        "div.redactor:not(.description)"
      )?.innerText;
      this.vendorEmail = this.extractEmails(this.vendorContactDetails);
    },
    extractEmails(text) {
      const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/g;
      return text.match(emailRegex) || [];
    },
    fetchSchoolDetails() {
      this.allSchoolNumber = [];
      this.budgetholderName = "";
      let allHostPart = window.location.hostname.split(".");
      if (allHostPart.length > 2) {
        allHostPart[0] = "app";
      } else {
        allHostPart = allHostPart.unshift("app");
      }
      fetch(
        window.location.protocol +
          "//" +
          allHostPart.join(".") +
          "/api/profile",
        {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((response) => {
          return response.json();
        })
        .then((response) => {
          if (response.data && response.data.school) {
            const items = response.data.school;
            items.forEach((item) => {
              this.allSchoolNumber.push(item.number);
            });
          }
          if (
            response.data &&
            response.data.firstName &&
            response.data.lastName
          ) {
            this.budgetholderName =
              response.data.firstName + " " + response.data.lastName;
          }
        });
    },
  },
};
</script>
