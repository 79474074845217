<template>
  <popup-window v-if="budgetValue >= 5.5" v-slot="scope" ref="popupWindow">
    <h2 class="title title-l">Tegoed uitgeven?</h2>
    <p>
      Je hebt €{{ budgetValueParsed }} en houdt hierna €{{ budgetAfter }} over.
    </p>
    <div class="buttons">
      <button class="button--primary" @click="goTo">
        <i class="icon fas fa-shopping-basket"></i>{{ "Order now" | t }}
      </button>
      <button class="button--secondary" @click="scope.close">
        Nee, ik kijk nog even verder
      </button>
    </div>
  </popup-window>
  <popup-window v-else v-slot="scope" ref="popupWindow">
    <h2 class="title title-l">Helaas</h2>
    <p>Je hebt onvoldoende CJP-tegoed</p>
    <div class="buttons">
      <button class="button--primary" @click="scope.close">Sluiten</button>
    </div>
  </popup-window>
</template>

<script>
import EventBus from "./../event-bus";
import { SESSION_STORAGE_BUDGET_VALUE } from "../constants/sessionStorage";

export default {
  name: "PopupSpendBudget",
  props: {
    orderUrl: {
      type: String,
      default: null,
    },
    orderTarget: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isLoggedIn: false,
      budget: null,
    };
  },
  computed: {
    budgetValue() {
      return this.budget;
    },
    budgetValueParsed() {
      return this.budget
        ? parseFloat(this.budget)
            .toFixed(2)
            .replace(".", ",")
        : this.budget;
    },
    budgetAfter() {
      const budgetAfter = this.budget - 5.5;
      return budgetAfter.toFixed(2).replace(".", ",");
    },
  },
  mounted() {
    EventBus.$on("openPopupSpendBudget", () => {
      this.$refs.popupWindow.openPopUp();
      this.budget = sessionStorage.getItem(SESSION_STORAGE_BUDGET_VALUE);
    });
  },
  methods: {
    goTo() {
      this.$refs.popupWindow.closePopUp();
      if (this.orderTarget === "_blank") {
        return window.open(this.orderUrl, "_blank");
      }
      return (window.location.href = this.orderUrl);
    },
  },
};
</script>
